import React from 'react';
import { graphql } from 'gatsby';

import Bio from '../components/Bio';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import { rhythm } from '../utils/typography';
import { Sidebar } from '../components/Sidebar';
import Book from '../components/Book';
import { Link } from '@chakra-ui/react';
import ContentContainer from '../components/ContentContainer';

class BlogIndex extends React.Component {
  render() {
    return (
      <Layout location={this.props.location} title={''}>
        <SEO
          title="Welcome"
          keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        />
        <ContentContainer>
          <Sidebar />
          <article className="flex flex-col list-none flex-1 flex-shrink min-w-0 items-center mt-16 max-w-2xl mx-auto">
            <Book />
            <h1>Welcome to Robust UI</h1>
            <p>
              Robust UI is designed to serve as a reference book when you come
              across a new testing challenge. Please reach out to me at{' '}
              <Link href="mailto:sam@samdawson.dev">sam@samdawson.dev</Link>{' '}
              when you come across something UI testing which is not addressed.
            </p>
            <p>
              This book is a work in progress. When new chapters are released or
              significant changes are made I will post on Gumroad.
            </p>
          </article>
        </ContentContainer>
      </Layout>
    );
  }
}

export default BlogIndex;
